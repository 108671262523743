import {
  SWITCH_LANGUAGE,
  SWITCH_CURRENT_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  GET_TRANSLATION,
} from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
} from "../../constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: THEME_COLOR,

  pathname: "/",
  width: window.innerWidth,
  isDirectionRTL: false,

  current_language: {
    id: 1,
    label: "English",
    short_code: "EN",
    is_default: true,
    online_id: 1,
  },
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  }, 

  exportingLanguage: {
    id: 1,
    label: "English",
    short_code: "EN",
    is_default: true,
    online_id: 1,
  },

  translation: JSON.parse(localStorage.getItem("translation")),
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType,
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        exportingLanguage: action.payload,
      };

    case SWITCH_CURRENT_LANGUAGE:
      return {
        ...state,
        current_language: action.payload,
      };
    case GET_TRANSLATION:
      return {
        ...state,
        translation: action.payload,
      };

    default:
      return state;
  }
};

export default settings;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => (
  <Scrollbars
    {...props}
    style={{marginBottom: -17}}
    // autoHide
    renderThumbVertical={({ style, ...props}) => (
      <div

        style={{
          ...style,
          display:'flex',
          flexGrow:1,
          cursor: "pointer",
          float: "right",
          borderRadius: "5px",
          background: "grey",
          marginBottom: -17

        }}
        className="track-vertical"
      />
    )}

  />
);

export default CustomScrollbars;

import {
  GET_All_SURVEYORS_SUCCESS,
  ON_ADD_SURVEYORS_SUCCESS,
  GET_All_ALL_SURVEYORS_SUCCESS,
  GET_All_PROJECT_SURVEYORS_SUCCESS,
  DELETE_SURVEYORS_SUCCESS,
  GET_SURVEY_SUCCESS,
  GET_PROJECT_SUCCESS,
  GET_All_QUESTION_CATEGORY_SUCCESS,
  ON_ADD_QUESTION_CATEGORY_SUCCESS,
  UPDATE_QUESTION_CATEGORY_SUCCESS,
  DELETE_QUESTION_CATEGORY_SUCCESS,
  GET_All_RECURRING_QUESTION_SUCCESS,
  ON_ADD_RECURRING_QUESTION_SUCCESS,
  UPDATE_RECURRING_QUESTION_SUCCESS,
  DELETE_RECURRING_QUESTION_SUCCESS,
  GET_All_SURVEY_PART_SUCCESS,
  GET_All_SURVEY_PART_CATEGORY_SUCCESS,
  ON_ADD_SURVEY_PART_CATEGORY_SUCCESS,
  UPDATE_SURVEY_PART_CATEGORY_SUCCESS,
  DELETE_SURVEY_PART_CATEGORY_SUCCESS,
  GET_All_SURVEY_PART_QUESTION_SUCCESS,
  ON_ADD_SURVEY_PART_QUESTION_SUCCESS,
  UPDATE_SURVEY_PART_QUESTION_SUCCESS,
  DELETE_SURVEY_PART_QUESTION_SUCCESS,
  ON_UPDATE_SURVEY_PART_SUCCESS,
  REORDER_QUESTIONS_SUCCESS,
  REORDER_QUESTIONS_REQUEST,
  REORDER_QUESTIONS_FAILED,
  GET_All_EMPLOYEE_SURVEYORS_SUCCESS,
  ON_ADD_EMPLOYEE_SURVEYORS_SUCCESS,
  DELETE_EMPLOYEE_SURVEYORS_SUCCESS,
  GET_All_ALL_EMPLOYEE_SURVEYORS_SUCCESS,
  GET_EMPLOYEE_SURVEY_SUCCESS,
  GET_All_EMPLOYEE_SURVEY_PART_SUCCESS,
  GET_All_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS,
  ON_ADD_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS,
  DELETE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS,
  UPDATE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS,
  ON_ADD_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  UPDATE_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  DELETE_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  GET_All_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  GET_All_EMPLOYEE_QUESTION_CATEGORY_SUCCESS,
  ON_ADD_EMPLOYEE_QUESTION_CATEGORY_SUCCESS,
  UPDATE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS,
  DELETE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS,
  GET_All_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  ON_ADD_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  UPDATE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
  DELETE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  surveyors: [],
  employeeSurveyors: [],
  allSurveyors: [],
  allEmployeeSurveyors: [],
  survey: {},
  employeeSurvey: {},
  questionCategories: [],
  employeeQuestionCategories: [],
  recurringQuestions: [],
  eRecurringQuestions: [],
  employeeRecurringQuestions: [],
  surveyParts: [],
  employeeSurveyParts: [],
  surveyPartCategories: [],
  employeeSurveyPartCategories: [],
  surveyPartQuestions: [],
  isReorderingLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_All_SURVEYORS_SUCCESS: {
      return {
        ...state,
        surveyors: action.payload,
      };
    }
    case GET_All_EMPLOYEE_SURVEYORS_SUCCESS: {
      return {
        ...state,
        employeeSurveyors: action.payload,
      };
    }
    case GET_All_PROJECT_SURVEYORS_SUCCESS: {
      return {
        ...state,
        projectSurveyors: action.payload,
      };
    }
    case GET_All_QUESTION_CATEGORY_SUCCESS: {
      return {
        ...state,
        questionCategories: action.payload,
      };
    }

    case ON_ADD_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        questionCategories: [...state.questionCategories, action.payload],
      };

    case UPDATE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        questionCategories: state.questionCategories.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        questionCategories: state.questionCategories.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_EMPLOYEE_QUESTION_CATEGORY_SUCCESS: {
      return {
        ...state,
        employeeQuestionCategories: action.payload,
      };
    }

    case ON_ADD_EMPLOYEE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        employeeQuestionCategories: [
          ...state.employeeQuestionCategories,
          action.payload,
        ],
      };

    case UPDATE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        employeeQuestionCategories: state.employeeQuestionCategories.map(
          (user) => (user.id === action.payload.id ? action.payload : user)
        ),
      };

    case DELETE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        employeeQuestionCategories: state.employeeQuestionCategories.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_SURVEY_PART_QUESTION_SUCCESS: {
      return {
        ...state,
        surveyPartQuestions: action.payload,
      };
    }

    case ON_ADD_SURVEY_PART_QUESTION_SUCCESS:
      return {
        ...state,
        surveyPartQuestions: [...state.surveyPartQuestions, action.payload],
      };

    case UPDATE_SURVEY_PART_QUESTION_SUCCESS:
      return {
        ...state,
        surveyPartQuestions: state.surveyPartQuestions.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_SURVEY_PART_QUESTION_SUCCESS:
      return {
        ...state,
        surveyPartQuestions: state.surveyPartQuestions.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS: {
      return {
        ...state,
        employeeSurveyPartCategories: action.payload,
      };
    }

    case ON_ADD_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS:
      return {
        ...state,
        employeeSurveyPartCategories: [
          ...state.employeeSurveyPartCategories,
          action.payload,
        ],
      };

    case UPDATE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS:
      return {
        ...state,
        employeeSurveyPartCategories: state.employeeSurveyPartCategories.map(
          (user) => (user.id === action.payload.id ? action.payload : user)
        ),
      };

    case DELETE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS:
      return {
        ...state,
        employeeSurveyPartCategories: state.employeeSurveyPartCategories.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_SURVEY_PART_CATEGORY_SUCCESS: {
      return {
        ...state,
        surveyPartCategories: action.payload,
      };
    }

    case ON_ADD_SURVEY_PART_CATEGORY_SUCCESS:
      return {
        ...state,
        surveyPartCategories: [...state.surveyPartCategories, action.payload],
      };

    case UPDATE_SURVEY_PART_CATEGORY_SUCCESS:
      return {
        ...state,
        surveyPartCategories: state.surveyPartCategories.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_SURVEY_PART_CATEGORY_SUCCESS:
      return {
        ...state,
        surveyPartCategories: state.surveyPartCategories.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_EMPLOYEE_RECURRING_QUESTION_SUCCESS: {
      return {
        ...state,
        employeeRecurringQuestions: action.payload,
      };
    }

    case GET_All_RECURRING_QUESTION_SUCCESS: {
      return {
        ...state,
        recurringQuestions: action.payload,
      };
    }

    case GET_All_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS: {
      return {
        ...state,
        eRecurringQuestions: action.payload,
      };
    }

    case REORDER_QUESTIONS_REQUEST: {
      return {
        ...state,
        isReorderingLoading: true,
      };
    }
    case REORDER_QUESTIONS_FAILED: {
      return {
        ...state,
        isReorderingLoading: false,
      };
    }

    case REORDER_QUESTIONS_SUCCESS: {
      return {
        ...state,
        recurringQuestions: Array.from(action.payload.questions),
        isReorderingLoading: false,
      };
    }

    case ON_ADD_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        recurringQuestions: [...state.recurringQuestions, action.payload],
      };

    case UPDATE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        recurringQuestions: state.recurringQuestions.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        recurringQuestions: state.recurringQuestions.filter(
          (user) => user.id !== action.payload
        ),
        selectedQuestion: null,
      };

    case ON_ADD_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        eRecurringQuestions: [...state.eRecurringQuestions, action.payload],
      };

    case UPDATE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        eRecurringQuestions: state.eRecurringQuestions.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        eRecurringQuestions: state.eRecurringQuestions.filter(
          (user) => user.id !== action.payload
        ),
        selectedQuestion: null,
      };

    case ON_ADD_EMPLOYEE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        employeeRecurringQuestions: [
          ...state.employeeRecurringQuestions,
          action.payload,
        ],
      };

    case UPDATE_EMPLOYEE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        employeeRecurringQuestions: state.employeeRecurringQuestions.map(
          (user) => (user.id === action.payload.id ? action.payload : user)
        ),
      };

    case DELETE_EMPLOYEE_RECURRING_QUESTION_SUCCESS:
      return {
        ...state,
        employeeRecurringQuestions: state.employeeRecurringQuestions.filter(
          (user) => user.id !== action.payload
        ),
        selectedQuestion: null,
      };

    case GET_All_SURVEY_PART_SUCCESS: {
      return {
        ...state,
        surveyParts: action.payload,
      };
    }

    case GET_All_EMPLOYEE_SURVEY_PART_SUCCESS: {
      return {
        ...state,
        employeeSurveyParts: action.payload,
      };
    }

    case ON_UPDATE_SURVEY_PART_SUCCESS:
      return {
        ...state,
        surveyParts: state.surveyParts.map((sp) =>
          sp.id === action.payload.id ? action.payload : sp
        ),
      };

    case GET_SURVEY_SUCCESS: {
      return {
        ...state,
        survey: action.payload,
      };
    }

    case GET_EMPLOYEE_SURVEY_SUCCESS: {
      return {
        ...state,
        employeeSurvey: action.payload,
      };
    }

    case GET_PROJECT_SUCCESS: {
      return {
        ...state,
        project: action.payload,
      };
    }

    case GET_All_ALL_SURVEYORS_SUCCESS: {
      return {
        ...state,
        allSurveyors: action.payload,
      };
    }

    case GET_All_ALL_EMPLOYEE_SURVEYORS_SUCCESS: {
      return {
        ...state,
        allEmployeeSurveyors: action.payload,
      };
    }

    case DELETE_SURVEYORS_SUCCESS:
      return {
        ...state,
        surveyors: state.surveyors.filter(
          (surveyor) => surveyor.id !== action.payload
        ),
      };

    case DELETE_EMPLOYEE_SURVEYORS_SUCCESS:
      return {
        ...state,
        employeeSurveyors: state.employeeSurveyors.filter(
          (surveyor) => surveyor.id !== action.payload
        ),
      };

    case ON_ADD_SURVEYORS_SUCCESS:
      return {
        ...state,
        surveyors: [...state.surveyors, ...action.payload],
      };

    case ON_ADD_EMPLOYEE_SURVEYORS_SUCCESS:
      return {
        ...state,
        employeeSurveyors: [...state.employeeSurveyors, ...action.payload],
      };

    default:
      return state;
  }
};

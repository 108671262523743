import React from "react";
import { Button, Form, Input } from "antd";
import IntlMessages from "util/IntlMessages";
import { useDispatch } from "react-redux";
import { confirmResetPassword, getUser } from "../appRedux/actions/Auth";
import { useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const FormItem = Form.Item;

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParamsString = location.search.substring(1),
    searchParams = new URLSearchParams(queryParamsString);
  const token = searchParams.get("token");

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", { ...values, token });
        dispatch(confirmResetPassword({ ...values, token }));
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  const { getFieldDecorator } = props.form;
  var bg = require("assets/images/bg.jpg");

  return (
    <div
      className="gx-app-login-wrap"
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <img
              src={require("assets/images/finbit.png")}
              style={{
                height: "auto",
                width: "auto",
                "max-width": 350,
                "max-height": 200,
                marginLeft: 40,
              }}
              alt="FINBIT Admin"
              title="FINBIT"
            />
          </div>

          <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(<Input type="password" placeholder="New Password" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input placeholder="Retype New Password" type="password" />)}
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                <IntlMessages id="app.userAuth.reset" />
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default WrappedResetPasswordForm;

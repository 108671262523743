import zhMessages from "../locales/zh-Hans.json";

const ZhLan = {
  messages: {
    ...zhMessages,
  },
  antd: null,
  locale: "zh-Hans-CN",
};
export default ZhLan;

const getStrings = (item) => 
{
  const messages = [];
  if (Array.isArray(item))
  {
    item.map((subItem) => {
      messages.push(...getStrings(subItem));
    })
  }
  else if (typeof item === "string")
  {
    messages.push(item);
  }
  else if (typeof item === "object")
  {
    for (let key in item)
    {
      const keyItems = getStrings(item[key]);
      keyItems.map((keyItem, index) => {
        if (keyItem.indexOf("This field") >= 0)
        {
          keyItems[index] = keyItem.replace("This field", key.replace("_", ""))
        }
        else if (keyItem.indexOf("this field") >= 0)
        {
          keyItems[index] = keyItem.replace("this field", key.replace("_", ""))
        }
      })
      messages.push(...keyItems)
    }
  }
  return messages;
}

const HandleError = (error) => {
  const errorMessages = [];
  if (error.response)
  {
    const errorData = error.response.data;
    if (errorData.non_field_errors)
    {
      errorMessages.push(...getStrings(errorData.non_field_errors));
    }
    else if (errorData.detail)
    {
      errorMessages.push(...getStrings(errorData.detail));
    }
    else{
      errorMessages.push(...getStrings(errorData));
    }
  }

  return errorMessages;
};

export default HandleError;

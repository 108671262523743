import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "util/Api";

function useUser() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);


  const { token } = useSelector(({ auth }) => auth);

  axios.defaults.headers.common["authorization"] = "JWT " + token;
  useEffect(() => {
    setLoading(true);
    setData(null);
    axios
      .get(`/auth/me/`)
      .then(({ data }) => {
        if (data) {
          localStorage.setItem("authUser", JSON.stringify(data));
        }
        setData(data);
      })
      .finally(() => setLoading(false));
  },[]);

  return [loading, data];
}

export default useUser;

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Surveyor from "./Surveyor";
import Participant from "./Participant";
import SurveyParts from "./SurveyParts";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    surveyors: Surveyor,
    participants: Participant,
    surveyParts: SurveyParts,
  });

export default createRootReducer;

const HasPermission = (authUser, key) => {
  // Check for the existence of authUser more concisely.
  if (!authUser || authUser.dashboard_setting === null) {
    return false;
  }

  const role = authUser.profile.role;

  // Simplify role checks by returning early.

  // CLient
  if (role === "client") {
    return true;
  }

  // Admin and is_staff
  if (role === "admin" && authUser.profile.user.is_staff) {
    return true;
  }

  // Use 'find' to stop once the key is found.
  const permission = authUser.dashboard_setting.permissions.find(
    (permission) => permission.dashboard_setting_permission_key === key
  );

  // Return the value if permission is found, else false.
  return permission ? permission.value : false;
};

export default HasPermission;

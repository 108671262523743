import {
  GET_P_All_RESEARCHERS_SUCCESS,
  GET_P_All_SURVEYORS_SUCCESS,
  GET_P_All_PARTICIPANTS_SUCCESS,
  GET_P_All_PROJECT_PARTICIPANTS_SUCCESS,
  GET_P_SURVEY_SUCCESS,
  GET_P_PROJECT_SUCCESS,
  DELETE_PARTICIPANT_SUCCESS,
  ON_ADD_PARTICIPANT_SUCCESS,
  ON_UPDATE_PARTICIPANT_SUCCESS,
  GET_All_EMPLOYEE_PARTICIPANTS_SUCCESS,
  GET_P_EMPLOYEE_SURVEY_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  researchers: [],
  pSurveyors: [],
  participants: [],
  employeeParticipants: [],
  projectParticipants: [],
  project: {},
  survey: {},
  employeeSurvey: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_P_All_RESEARCHERS_SUCCESS: {
      return {
        ...state,
        researchers: action.payload,
      };
    }

    case GET_P_All_SURVEYORS_SUCCESS: {
      return {
        ...state,
        pSurveyors: action.payload,
      };
    }

    case GET_P_All_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        participants: action.payload,
      };
    }

    case GET_All_EMPLOYEE_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        employeeParticipants: action.payload,
      };
    }

    case GET_P_All_PROJECT_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        projectParticipants: action.payload,
      };
    }

    case GET_P_SURVEY_SUCCESS: {
      return {
        ...state,
        survey: action.payload,
      };
    }

    case GET_P_EMPLOYEE_SURVEY_SUCCESS: {
      return {
        ...state,
        employeeSurvey: action.payload,
      };
    }

    case GET_P_PROJECT_SUCCESS: {
      return {
        ...state,
        project: action.payload,
      };
    }

    case DELETE_PARTICIPANT_SUCCESS:
      console.log(state.participants);
      console.log(action.payload);
      return {
        ...state,
        participants: state.participants.filter(
          (participant) => participant.id !== action.payload
        ),
      };

    case ON_ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        participants: [...state.participants, ...action.payload],
      };

    case ON_UPDATE_PARTICIPANT_SUCCESS:
      if (action.payload.surveyor === null) {
        return {
          ...state,
          participants: state.participants.map((participant) =>
            participant.id === action.payload.id
              ? {
                  ...participant,
                  surveyor: null,
                }
              : participant
          ),
        };
      }
      return {
        ...state,
        participants: state.participants.map((participant) =>
          participant.id === action.payload.id
            ? {
                ...participant,
                surveyor: state.pSurveyors.filter(
                  (s) => s.surveyor.id === action.payload.surveyor
                )[0].surveyor,
              }
            : participant
        ),
      };
    default:
      return state;
  }
};

import {
  GET_SURVEY_PARTS_SUCCESS,
  GET_S_SURVEY_SUCCESS,
  ON_UPDATE_SURVEY_PART_SUCCESS,
  GET_SURVEY_PART_SUCCESS,
  GET_QUESTION_CATEGORIES_SUCCESS,
  ON_ADD_QUESTION_CAATEGORY,
  ON_UPDATE_QUESTION_CAATEGORY,
  ON_DELETE_QUESTION_CATEGORY_SUCCESS,
  GET_ME_SUCCESS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_CATEGORY_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  surveyParts: [],
  survey: {},
  surveyPart: {},
  questionCategories: [],
  questionsCategory: {},
  questions: [],
  me: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SURVEY_PARTS_SUCCESS: {
      return {
        ...state,
        surveyParts: action.payload,
      };
    }

    case GET_S_SURVEY_SUCCESS: {
      return {
        ...state,
        survey: action.payload,
      };
    }

    case ON_UPDATE_SURVEY_PART_SUCCESS:
      return {
        ...state,
        surveyParts: state.surveyParts.map((sp) =>
          sp.id === action.payload.id ? action.payload : sp
        ),
      };

    case GET_SURVEY_PART_SUCCESS: {
      return {
        ...state,
        surveyPart: action.payload,
      };
    }

    case GET_QUESTION_CATEGORIES_SUCCESS: {
      return {
        ...state,
        questionCategories: action.payload,
      };
    }

    case ON_ADD_QUESTION_CAATEGORY: {
      return {
        ...state,
        questionCategories: [...state.questionCategories, action.payload],
      };
    }

    case ON_UPDATE_QUESTION_CAATEGORY:
      return {
        ...state,
        questionCategories: state.questionCategories.map((qc) =>
          qc.id === action.payload.id ? action.payload : qc
        ),
      };

    case ON_DELETE_QUESTION_CATEGORY_SUCCESS:
      return {
        ...state,
        questionCategories: state.questionCategories.filter(
          (qc) => qc.id !== action.payload
        ),
      };

    case GET_QUESTIONS_CATEGORY_SUCCESS: {
      console.log("QC", action.payload);
      return {
        ...state,
        questionsCategory: action.payload,
      };
    }

    case GET_QUESTIONS_SUCCESS: {
      console.log("Q", action.payload);
      return {
        ...state,
        questions: action.payload,
      };
    }

    case GET_ME_SUCCESS: {
      console.log("Me", action.payload);
      return {
        ...state,
        me: action.payload,
      };
    }

    default:
      return state;
  }
};
